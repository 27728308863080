import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "button"]
  static classes = ["hidden"]

  toggle() {
    this.buttonTargets.forEach((item) => {
      item.classList.toggle('d-none')
    })

    this.contentTargets.forEach((item) => {
      item.classList.toggle(this.hiddenClass)
    })
  }
}
