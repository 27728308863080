import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static outlets = [ "highlight" ]

  connect() {
  }

  showSentence(e) {
    let dom_id = e.target.dataset.id
    this.highlightOutlet.show()
    this.highlightOutlet.scrollTo(dom_id)
    this.highlightOutlet.highlight(dom_id)
  }
}
