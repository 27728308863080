import { Controller } from "@hotwired/stimulus"

function findBoundary(text, start) {
  let sentences = text.match( /[^\.!\?]+([\.!\?]+|$)/gm )

  let result = {}
  let counter = 0

  sentences.some(sentence => {
    if (counter + sentence.length >= start) {
      result = {
        start: counter,
        end: counter + sentence.length,
      }
      return true
    } else {
      counter += sentence.length
      return false
    }
  })

  return result
}

export default class extends Controller {
  static values = {
    url: String,
    frame: String
  }

  connect() {
  }

  selectAndShowSource(e) {
    e.preventDefault()

    let text = this.element.value
    let start = this.element.selectionStart
    let end = this.element.selectionEnd

    if (start === end) {
      ({start, end}  = findBoundary(text, start))
      this.element.selectionStart = start
      this.element.selectionEnd = end
    }

    this.lastStart = this.element.selectionStart
    this.lastEnd = this.element.selectionEnd

    let selection = text.substring(start, end)

    this.open(selection)
  }

  clicked(e) {
    if (!this.lastStart && !this.lastEnd) {
      this.selectAndShowSource(e)
      return
    }

    if (this.lastStart < this.element.selectionStart && this.lastEnd > this.element.selectionEnd) {
      // clicked within selected range, do nothing
    } else {
      this.selectAndShowSource(e)
    }
  }

  showSource(e) {
    this.open(this.element.value)
  }

  open(text) {
    var url = new URL(this.urlValue, window.location.protocol + "//" + window.location.host);
    url.searchParams.append('text', text);

    Turbo.visit(url.href, { frame: this.frameValue })
  }
}
