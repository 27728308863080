import { StreamActions } from "@hotwired/turbo"
import {Utils} from "turbo_power";
import { Modal } from "bootstrap"

const PERMITTED_ATTRIBUTES = [

]

// https://github.com/simple-notify/simple-notify
function modal() {
  console.log(this.attributes)

  // const attributes = Array.from(this.attributes)
  //   .filter((attribute) => PERMITTED_ATTRIBUTES.includes(attribute.name))
  //   .map((attribute) => [Utils.camelize(attribute.name), Utils.typecast(attribute.value)])
  //
  // const options = Object.fromEntries(attributes)

  this.targetElements.forEach((element) => {
    var bsModal = Modal.getOrCreateInstance(element)
    bsModal.show()
  })
}

StreamActions.modal = modal
