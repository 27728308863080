import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'
import { useThrottle } from 'stimulus-use'
import { Offcanvas } from "bootstrap"

export default class extends Controller {
  static targets = ["sentence", "transcript", "label", "nextButton", "prevButton"]
  static values = {
    url: String
  }
  static throttles = ['scrolled']

  connect() {
    useThrottle(this)
    this.scrolled()
  }

  scrolled() {
    if (!this.hasTranscriptTarget) { return }

    let {nextElement, lastElement, currElement} = this.findNext()

    if (this.hasPrevButton) {
      this.prevButtonTarget.disabled = !lastElement
      this.nextButtonTarget.disabled = !nextElement

      let selectedList = this.selectedSentences()
      let size = selectedList.length
      let currPos = selectedList.indexOf(currElement)

      this.labelTarget.textContent = `${currPos} / ${size}`
    }
  }

  async clicked(e) {
    console.log(e)
    let el = e.target.closest(".sentence")
    if (!el || !el.dataset.seqId) {
      return
    }

    let selected = el.classList.contains("sentence-selected")

    el.classList.toggle("sentence-selected", !selected)
    this.scrolled()

    let method = selected ? "delete" : "post"
    const request = new FetchRequest(method, this.urlValue, {
      body: JSON.stringify({sequential_id: el.dataset.seqId})
    })
    const response = await request.perform()
    console.log(response)
  }

  next() {
    console.log("next")
    let {nextElement} = this.findNext()
    console.log(nextElement)
    nextElement.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
  }

  prev() {
    console.log("prev")
    let {lastElement} = this.findNext()
    console.log(lastElement)
    lastElement.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
  }

  scrollTo(dom_id) {
    document.getElementById(dom_id).scrollIntoView({behavior: "instant", block: "center", inline: "nearest"})
  }

  highlight(dom_id) {
    let elems = this.transcriptTarget.querySelectorAll(".sentence-chosen")
    elems.forEach((el) => { el.classList.remove("sentence-chosen") })

    document.getElementById(dom_id).classList.add("sentence-chosen")
  }

  show() {
    const bsOffcanvas = Offcanvas.getOrCreateInstance(this.element)
    bsOffcanvas.show()
  }

  scrollToTop() {
    this.transcriptTarget.scroll({top: 0, behavior: "smooth"})
  }

  selectedSentences() {
    return this.sentenceTargets
      .filter((el) => el.classList.contains("sentence-selected"))
  }

  findNext() {
    let parentTop = this.transcriptTarget.offsetTop
    let height = this.transcriptTarget.offsetHeight
    let position = this.transcriptTarget.scrollTop

    let lastElement
    let nextElement
    let currElement

    this.selectedSentences()
      .some(target => {
        let elementScroll = target.offsetTop - parentTop

        if (elementScroll < position) {
          lastElement = target
        }

        if (!currElement && elementScroll > position) {
          currElement = target
        }

        if (elementScroll > (position + height)) {
          nextElement = target
          return true
        }
      })

    return {nextElement, lastElement, currElement}
  }
}
